import React from 'react'

function Home() {
  return (
    <div className="home">
        <h1>GIAN T. CARLOS</h1>
        <h2>FRONT-END WEB DEVELOPER / DESIGNER</h2>
    </div>
  )
}

export default Home